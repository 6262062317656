<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة صرف مخزني جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.go(-1)"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-4">
        <div class="mb-3">
          <label for="name" class="form-label required">
            تاريخ المعامله
            <span>*</span>
          </label>
          <Calendar
            style="width: 100%;"
            v-model="body.date"
            :class="{ 'is-invalid': isInvalid && !body.date }"
            dateFormat="dd-mm-yy"
            :minDate="minDateValue"
            required
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 ">
          <label for="clientsId" class="form-label required">
            العميل
            <span>*</span>
          </label>

          <Dropdown
            :options="clientsList"
            class="form-select form-control text-right"
            style="direction:rtl"
            optionLabel="name"
            optionValue="id"
            :filter="true"
            v-model.number="body.clientsId"
            :class="{ 'is-invalid': isInvalid && !body.clientsId }"
            required
            placeholder="اسم العميل"
            :showClear="true"
          >
          </Dropdown>

          <!-- <select
            class="form-select form-control"
            id="clientsId"
            name="clientsId"
            v-model.number="body.clientsId"
            :class="{ 'is-invalid': isInvalid && !body.clientsId }"
            required
          >
            <option
              v-for="item of clientsList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select> -->
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="warehouseId" class="form-label required">
            المستودع
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="warehouseId"
            name="warehouseId"
            v-model.number="body.warehouseId"
            :class="{ 'is-invalid': isInvalid && !body.warehouseId }"
            required
          >
            <option
              v-for="item of warehouseList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div class="mb-3 text-right" style="direction:ltr">
          <label for="code" class="form-label text-right required">
            <span>*</span>
            قيمه المعامله
          </label>

          <input class="form-control" v-model.number="body.price" disabled />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 ">
          <label for="taxesId" class="form-label required">
            الضريبه
            <span>*</span>
          </label>
          <select
            class="form-select form-control"
            id="taxesId"
            name="taxesId"
            v-model="body.taxesId"
            :class="{ 'is-invalid': isInvalid && !body.taxesId }"
            required
          >
            <option v-for="item of taxesList" :key="item.id" :value="item">{{
              item.name
            }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 ">
          <label for="total" class="form-label">
            الاجمالى
          </label>
          <input class="form-control" v-model.number="body.total" disabled />
        </div>
      </div> -->
      <div class="col-md-12">
        <div class="mb-3">
          <label for="texxxt" class="form-label required">
            البيان
            <span>*</span>
          </label>

          <Editor
            v-model="body.texxxt"
            :class="{ 'is-invalid': isInvalid && !body.texxxt }"
            editorStyle="height: 320px"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-align" value=""></button>
                <button class="ql-align" value="center"></button>
                <button class="ql-align" value="right"></button>
                <button class="ql-align" value="justify"></button>
              </span>
            </template>
          </Editor>
        </div>
        <div class="mb-3 ">
          <label for="note" class="form-label">
            الملاحظات
          </label>
          <textarea
            class="form-control"
            type="note"
            id="note"
            v-model="body.note"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            class="p-ml-2 p-button-info"
            @click="update()"
            v-if="id"
            :disabled="disabledAdd"
          />
          <Button
            v-else
            label="اضافة جديد"
            icon="pi pi-plus"
            v-tooltip="'اضافه جديد'"
            class="p-ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        date: new Date(),
        texxxt: null,
        taxesId: null,
        warehouseId: null,
        clientsId: null,
        note: null,
        price: 0,
        total: 0,
      },
      productsAdd: {
        code: null,
        storeItemsId: null,
        quantity: 1,
        price: 1,
      },
      id: null,
      isInvalid: false,
      disabledAdd: false,
      displayProductsAdd: false,
      // storeItemsList: [],

      clientsList: [],
      warehouseList: [],
      taxesList: [],
    };
  },
  methods: {
    toQun() {
      this.$nextTick(() => this.$refs.focusMe.focus());
    },
    // addProducts() {
    //   if (
    //     this.productsAdd.storeItemsId.length > 0 &&
    //     this.productsAdd.quantity > 0
    //   ) {
    //     for (const item of this.productsAdd.storeItemsId) {
    //       this.body.storeItemsList.push({
    //         storeItemsId: item,
    //         quantity: this.productsAdd.quantity,
    //       });
    //     }
    //     this.productsAdd = {
    //       storeItemsId: [],
    //       code: null,
    //       quantity: 1,
    //     };
    //     this.displayProductsAdd = false;
    //   } else {
    //     this.$toast.add({
    //       severity: 'error',
    //       summary: 'هناك خطأ',
    //       detail: 'املاء الحقول',
    //       life: 3000,
    //     });
    //   }
    // },
    // deleteItem(index, data) {
    //   // this.body.price = this.body.price - data.quantity * data.price;

    //   this.body.storeItemsList.splice(index, 1);
    // },
    save() {
      if (
        this.body.date &&
        this.body.texxxt &&
        this.body.clientsId &&
        this.body.warehouseId
        // this.body.price > 0 &&
        // this.body.total > 0
      ) {
        this.disabledAdd = true;

        this.$http.post(`inventoryStoreCountingReq`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.go(-1);
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (
        this.body.date &&
        this.body.texxxt &&
        // this.body.storeItemsList.length > 0 &&
        this.body.clientsId &&
        this.body.warehouseId
      ) {
        this.disabledAdd = true;

        this.$http.put(`inventoryStoreCountingReq/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.disabledAdd = false;
            this.$router.go(-1);
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (!this.$checkRoles('inventoryStoreCountingReqAdd')) {
      this.$router.push('/admin/');
    }
    const user = JSON.parse(localStorage.admin);

    this.$http.get(`warehouse`).then((res) => {
      for (const item of res.data) {
      }
      this.warehouseList = res.data.filter((itm) =>
        itm.users.find((u) => u.id == user.id),
      );
    });

    this.$http.get(`taxes`).then((res) => {
      this.taxesList = res.data;
    });
    this.$http.get(`storeItems`).then((res) => {
      this.storeItemsList = res.data;
    });

    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`inventoryStoreCountingReq/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.warehouseId = res.data.warehouseId?.id;
          this.body.clientsId = res.data.clientsId?.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },

  // watch: {
  //   'body.price'(val) {
  //     if (this.body.taxesId && this.body.taxesId.value) {
  //       this.body.total = val + (val / 100) * this.body.taxesId.value;
  //     }
  //   },
  //   'body.taxesId'(val) {
  //     this.body.total = this.body.price + (this.body.price / 100) * val.value;
  //   },
  // },
};
</script>
